import styled    from "styled-components"

export default styled.h2`
font-family: "PT Serif","Times New Roman",serif;
font-size: 38px;
font-weight: 700;
line-height: 54px;
position: relative;
margin: 0 0 20px 0;
color: #1b1c1f;
`

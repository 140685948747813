import styled   from "styled-components"

export default styled.div`
display: inline-block;
font-size: 22px;
font-weight: 600;
color: #c4c8d2;
margin-right: 20px;
text-transform: capitalize;
`
